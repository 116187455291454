<!--  -->
<template>
    <div class='page-task-task-subject-summary flex-center'>
        <component-bcg url="task/c1.png"></component-bcg>
        <div class="bcg">
            <div class="board">
                <div class="task-room-bcg">
                    <div class="task-room flex-center" >
                        <span class="text-large-c text-purple-color bold room">{{routeDataCul.sectionName}}</span>
                    </div>
                </div>
                <div class="task-content-bcg">
                    <div class="task-content" v-show="!listLength&&loadingFlag">
                        <div class="content-mark"></div>
                        <div class="items-empty flex-center max-size ">
                            <span class="text-normal bold title">{{'题库暂未开放，敬请期待~'}}</span>
                        </div>
                    </div>
                    <div class="task-content" v-show="!listLength&&!loadingFlag">
                        <div class="content-mark"></div>
                        <div class="items-empty flex-center max-size ">
                            <span class="text-normal bold title">{{'题库正在紧急加载 稍等片刻~'}}</span>
                        </div>
                    </div>
                    <div class="task-content" v-show="listLength">
                        <div class="content-mark"></div>
                        <div class="items mini-scrollbar a" id="task-subject-section-a">
                            <div class="items-box" ref="commentBox">
                                <div class="item" v-for="(v,i) in sectionListCul" :key="i">
                                    <div class="item-bcg scale-button" @click="gotoSection(v)">
                                        <div class="tag-bcg" v-if="v.tagFlag">
                                            <span class="text-mini-b bold b">{{'上次练到这'}}</span>
                                        </div>
                                        <div class="result-box text-yel-color" :class="v.correct ? 'right': 'wrong'" v-if="v.userAnswer"></div>
                                        <div class="img-box">
                                            <img class="board-img" :src="v.mediaPath" v-if="v.mediaPath">
                                            <img class="board-img" :src="`${PARAMS.assetsUrl}/img/v2_0/task/board9.png`" v-else>
                                        </div>
                                        <div class="item-title">
                                            <span class="text-mini bold b text-yel-color" v-if="!v.userAnswer">{{`${v.exerciseNo+'-未作答'}`}}</span>
                                            <span class="text-mini bold b text-yel-color" :class="{right: v.correct}" v-else-if="v.correct">{{`${v.exerciseNo+'-作答正确'}`}}</span>
                                            <span class="text-mini bold b text-yel-color" :class="{wrong: !v.correct}" v-else>{{`${v.exerciseNo+'-作答错误'}`}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="items mini-scrollbar b" id="task-subject-section-b">
                            <div class="items-box" ref="commentBox">
                                <div class="item" v-for="(v,i) in sectionListCul" :key="i">
                                    <div class="item-bottom-bcg scale-button">
                                        <div class="tag-bcg" v-if="v.tagFlag">
                                            <span class="text-mini-b bold b">{{'上次练到这'}}</span>
                                        </div>
                                        <div class="result-box text-yel-color" :class="v.correct ? 'right': 'wrong'" v-if="v.userAnswer"></div>
                                        <div class="img-box">
                                        </div>
                                        <div class="item-title">
                                            <span class="text-mini bold b text-yel-color" v-if="!v.userAnswer">{{`${v.exerciseNo+'-未作答'}`}}</span>
                                            <span class="text-mini bold b text-yel-color" :class="{right: v.correct}" v-else-if="v.correct">{{`${v.exerciseNo+'-作答正确'}`}}</span>
                                            <span class="text-mini bold b text-yel-color" :class="{wrong: !v.correct}" v-else>{{`${v.exerciseNo+'-作答错误'}`}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import apiClass from '@/API/page/task'
    import ComponentBcg from '../../../components/componentBcg.vue'
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                routeData: this.$route.params.routeData,
                sectionList: [],
                loadingFlag: false,
                diffcultyList: []
            };
        },
        methods: {
            async pageInit(){
                this.configInit()
                this.subjectExerciseList()
                this.eventSet()
            },
            async configInit(){
                let res = await this.api.configLoad('subject_diffculty')
                if(!res) return
                this.diffcultyList = res
            },
            async subjectExerciseList(){
                this.loadingFlag = false
                let {routeDataCul, difficulty} = this
                let opt = {
                    school_id: this.api.PARAMS.school_id,
                    section_id: routeDataCul.id,
                    course_id: routeDataCul.courseId,
                    difficulty
                }
                let res = await this.api.subjectExerciseList(opt)
                if(!res) return
                this.loadingFlag = true
                this.sectionList = res
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('taskSectionBackFun', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('taskSectionBackFun')
            },
            back(){
                let { routeDataCul } = this
                let name = 'task-subject-chapter'
                let type = 'replace'
                let params = {...routeDataCul}
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, type, params})
            },
            gotoSection(opt){
                let {routeDataCul} = this 
                let name = 'task-subject-action'
                let type = 'replace'
                let params = {
                    ...routeDataCul,
                    exerciseId: opt.id,
                }
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, type, params})
            },
            scrollHander(){
                document.getElementById('task-subject-section-b').scrollTop = document.getElementById('task-subject-section-a').scrollTop
            },
            scrollListener(){
                let eleA = document.getElementById('task-subject-section-a')
                let eleB = document.getElementById('task-subject-section-b')
                if(eleA && eleB) eleA.addEventListener("scroll", this.scrollHander);
            },

            // 更新列表棋谱图片 --test
            async updateImage(){
                let opt = {
                    school_id: 1,
                }
                let res = await this.api.updateImage(opt)
            },
            // 上传sgf生成图片 --test
            async createImageBoardFile(){
                let sgf = '(;CA[utf8]AP[golaxy]SZ[19]PB[农夫三拳]PW[乐乐]RE[W+R]KM[0.000000];B[bd];W[nf];B[ek];W[pk];B[ge];W[lk];B[gk];W[jf];B[if];W[ml];B[fg])' //19路
                // let sgf = '(;CA[utf8]AP[golaxy]GN[我的新棋谱]HA[0]DT[2022-10-25]KM[7.5]SZ[13]ST[0];B[be];W[bf];B[bg];W[bh];B[bi];W[le];B[lf];W[lg];B[lh];W[li];B[lj];W[bj];B[ld];W[bd])' //13路
                // let sgf = '(;CA[utf8]AP[golaxy]GN[我的新棋谱]HA[0]DT[2022-10-25]KM[7.5]SZ[9]ST[0];B[cb];W[bb];B[bc];W[he];B[fe];W[bf];B[bh];W[gh];B[ih];W[dh];B[hc];W[eb])' //9路
                let opt = {
                    sgf: sgf,
                }
                let res = await this.api.createImageBoardFile(opt)
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            ComponentBcg,
        },
        computed: {
            difficultySelected(){
                let {diffcultyList: list, difficulty: value} = this
                let data = {}
                for(let i in list){
                    let {resourceDetail: detail} = list[i]
                    detail = detail || {}
                    if(detail.value == value){
                        data = {
                            ...list[i],
                            ...detail
                        }
                        break
                    }
                }
                return data
            },
            sectionListCul(){
                let list = this._common.deepCopy(this.sectionList)
                let tagItem = ''
                for (const i in list) {
                    if(list[i].latestAnswer && i < list.length-1) tagItem = parseInt(i)+1
                    list[i].exerciseNoCul = Number(i) + 1
                }
                if(tagItem) list[tagItem].tagFlag = true
                return list
            },
            listLength(){
                let {sectionListCul:list} = this
                return list.length
            },
            routeDataCul(){
                let {routeData} = this
                return this._common.routeDataCul(routeData)
            },
            difficulty(){
                let {difficulty} = this.routeDataCul
                return difficulty
            }
        },
        watch: {
        },
        mounted() {
            this.scrollListener()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
    
    .page-task-task-subject-summary{
        @text-yel-color-empty:#8D7461;
        @text-yel-color:#8D7461;
        @text-purple-color:#59578E;
        @text-red-color:#991B3D;
        @text-pur-color:#6143AF;
        .text-pur-color{
            color: @text-pur-color;
        }
        .text-red-color{
            color: @text-red-color;
        }
        .text-yel-color{
            color: @text-yel-color;
        }
        .text-purple-color{
            color: @text-purple-color;
        }
        .chapter-result-box{
            position: relative;
            width: 285px;
            height: 323px;
        }
        .bcg{
            .background-img-max(url('@{assetsUrlV2_0}/task/c2.png'));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 638px;
            height: 375px;
            .board{
                .background-img-max(url('@{assetsUrlV2_0}/task/c3.png'));
                width: 621px;
                height: 360px;
                position: absolute;
                top: 10px;
                left: 10px;
                .task-room-bcg{
                    .background-img-max(url('@{assetsUrlV2_0}/task/c4.png'));
                    width: 209px;
                    height: 43px;
                    position: absolute;
                    top: 2px;
                    left: 206px;
                    .task-room{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c5.png'));
                        width: 193px;
                        height: 43px;
                        position: absolute;
                        top: -11px;
                        left: 7px;
                        .room{
                            position: relative;
                            top: -1px;
                        }
                    }
                }
                .task-content-bcg{
                    .background-img-max(url('@{assetsUrlV2_0}/task/c22.png'));
                    width: 580px;
                    height: 309px;
                    position: absolute;
                    top: 45px;
                    left: 21px;
                    .task-content{
                        width: 521px;
                        height: 245px;
                        position: absolute;
                        top: 25px;
                        left: 26px;
                        .background-img-max(url('@{assetsUrlV2_0}/task/c24.png'));
                        .items-empty{
                            .title{
                                color: @text-yel-color-empty;
                            }
                        }
                        .content-mark{
                            // .background-img-max(url('@{assetsUrlV2_0}/task/c23.png'));
                            width: 521px;
                            height: 245px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            pointer-events: none;
                            border-radius: 6px;
                            box-shadow: inset 0px 0px 4px 3px rgba(215,183,169,0.75);
                        }
                        .item.a{
                            pointer-events: none;
                            z-index: 1;
                        }
                        .items.b{
                            pointer-events: none;
                            z-index: 3;
                            .items-box{
                                visibility: hidden;
                            }
                        }
                        .items{
                            width: 525px;
                            height: 245px;
                            position: absolute;
                            .items-box{
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                padding: 0 12px;
                                padding-bottom: 16px;
                            }
                            .item{
                                // .background-img-max(url('@{assetsUrlV2_0}/task/c13.png'));
                                width: 25%;
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-top: 16px;
                                .item-bcg{
                                    width: 106px;
                                    height: 132px;
                                    border-radius: 10px;
                                    .background-img-max(url('@{assetsUrlV2_0}/task/c28.png'));
                                    position: relative;
                                    // box-shadow: 0px 0px 5px 3px #d48c50 inset;
                                    // background: #fff6eb;
                                    .tag-bcg{
                                        .background-img-max(url('@{assetsUrlV2_0}/task/c29.png'));
                                        width: 60px;
                                        height: 15px;
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        z-index: 1;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        span{
                                            color: #FFFFFF;
                                        }
                                    }
                                    .result-box.right{
                                        .background-img-max(url('@{assetsUrlV2_0}/task/result_right.png'));
                                    }
                                    .result-box.wrong{
                                        .background-img-max(url('@{assetsUrlV2_0}/task/result_wrong.png'));
                                    }
                                    .result-box{
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        width: 20px;
                                        height: 20px;
                                        background: #ffffff;
                                        border-radius: 10px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        z-index: 1;
                                    }
                                    .img-box{
                                        width: 90px;
                                        height: 90px;
                                        border-radius: 5px;
                                        background: #fbd67b;
                                        position: absolute;
                                        top: 8px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        .board-img{
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    .item-title{
                                        position: absolute;
                                        top: 108px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        display: flex;
                                        justify-content: center;
                                    }
                                }
                                .item-bottom-bcg{
                                    width: 106px;
                                    height: 132px;
                                    border-radius: 10px;
                                    // .background-img-max(url('@{assetsUrlV2_0}/task/c28.png'));
                                    position: relative;
                                    // box-shadow: 0px 0px 5px 3px #d48c50 inset;
                                    // background: #fff6eb;
                                    .tag-bcg{
                                        // .background-img-max(url('@{assetsUrlV2_0}/task/c29.png'));
                                        width: 60px;
                                        height: 15px;
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        z-index: 1;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        span{
                                            color: #FFFFFF;
                                        }
                                    }
                                    .result-box.right{
                                        // .background-img-max(url('@{assetsUrlV2_0}/task/result_right.png'));
                                    }
                                    .result-box.wrong{
                                        // .background-img-max(url('@{assetsUrlV2_0}/task/result_wrong.png'));
                                    }
                                    .result-box{
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        width: 20px;
                                        height: 20px;
                                        background: #ffffff;
                                        border-radius: 10px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        z-index: 1;
                                    }
                                    .img-box{
                                        width: 90px;
                                        height: 90px;
                                        border-radius: 5px;
                                        background: #fbd67b;
                                        position: absolute;
                                        top: 8px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        .board-img{
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    .item-title{
                                        position: absolute;
                                        top: 108px;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        display: flex;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /deep/.com-confirm-button{
            width: 100%;
            height: 100%;
        }
        // 滚动条
        .mini-scrollbar::-webkit-scrollbar{
            width: 10px;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar-x::-webkit-scrollbar{
            height: 4px;
        }
        .mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb{
            border-radius: 10px;
            // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            // background: rgba(0,0,0,0.2);
            .background-img-max(url('@{assetsUrlV2_0}/task/c26.png'));
            // background: #FFFAE6;
            // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
            // background-position: center;
            
        }
        .mini-scrollbar::-webkit-scrollbar-track-piece{
            // background: red;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar::-webkit-scrollbar-thumb::before{
            content: '';
            .background-img-max(url('@{assetsUrlV2_0}/task/c26.png'));
        }
        .mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track{
            // -webkit-box-shadow: inset 0 0 5px #C7946A;
            border-radius: 10px;
            // background: rgba(178,123,85,0.1);
            // background: #B27B55;
            background-image: linear-gradient(to right, #D2B7A2, #DDCDB8, #D2B7A2);
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar{
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    
</style>